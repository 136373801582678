import dayjs from 'dayjs';
import {maskJs} from 'mask-js';
export const OpemUrl = url => url ? window.open(item, '_blank') : '';
export const FormatDate = date => date ? dayjs(date).format('DD/MM/YYYY') : "";
export const FormatDateShort = date => date ? dayjs(date).format('DD/MM/YY') : "";
export const FormatDateTime = date => date ? dayjs(date).format('DD/MM/YYYY HH:mm') : "";
export const FormatDateTimeShort = date => date ? dayjs(date).format('DD/MM HH:mm') : "";
export const FormatZipcode = v => v ? maskJs('99999-999', v) : '';
export const FormatCpf = cpf => cpf ? maskJs('999.999.999-99', cpf) : '';
export const FormatCnpj = cnpj => cnpj ? maskJs('99.999.999/9999-99', cnpj) : '';
export const FormatPhone = phone => phone ? maskJs('(99) 9999?9-9999', phone) : '';
export const FormatMoney = v => {
	let val = (v / 1).toFixed(2).replace('.', ',')
	return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export const FormatStatus = v => {
	switch (v){
		case 'pending': return 'Pendente'; 	break;
		case 'approved': return 'Aprovado'; break;
		case 'rejected':  return 'Rejeitado'; break;
	}
	
}

export const FormatPaymentMethod = v => {
	switch (v){
		case 1:	return 'Dinheiro'; break;
		case 2: return 'Pix'; 	break;
		case 3:  return 'Cartão Débito'; break;
		case 4:  return 'Cartão Crédito'; break;
		case 5:  return 'Outro'; break;
	}	
}



export const FirstName = v => {
	var names = v.split(" ");
	return names[0];
	
}