import Vue from "vue";
import Router from "vue-router";

const routes = [];

const requireAuth = async(to, from, next) => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data.user) {
        next();
    } else {
        next("/login");
    }
};

routes.push({
    path: "/dashboard",
    component: () =>
        import ("../pages/Orders/requests"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/unity/:id/view",
    component: () =>
        import ("../components/Unity/view.vue"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/catalogs",
    component: () =>
        import ("../pages/Factory/Categories"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/catalog/:id/:name",
    component: () =>
        import ("../pages/Catalogs/list"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/catalog/:id/category/:category",
    component: () =>
        import ("../pages/Catalogs/list"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/catalog/module/:id/:name",
    component: () =>
        import ("../pages/Catalogs/list_module"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/banners/catalogs",
    component: () =>
        import ("../pages/Banners"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/product/:product_id/:name",
    component: () =>
        import ("../pages/Catalogs/view"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/products/list",
    component: () =>
        import ("../pages/Products"),
    beforeEnter: requireAuth,
});



routes.push({
    path: "/products/dashbord",
    component: () =>
        import ("../pages/ProductsDash"),
    beforeEnter: requireAuth,
});
routes.push({
    path: "/products/dashbord/view/:id",
    component: () =>
        import ("../pages/ProductsDash/view"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/banners/catalogs",
    component: () =>
        import ("../pages/Banners"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/requests",
    component: () =>
        import ("../pages/Orders/requests"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/orders/items/:id",
    component: () =>
        import ("../pages/Orders/items"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/orders/items/:id/:category_id",
    component: () =>
        import ("../pages/Orders/items-list"),
    beforeEnter: requireAuth,
});


routes.push({
    path: "/to_do_lists",
    component: () =>
        import ("../pages/ToDoList"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_lists/form/:id?",
    component: () =>
        import ("../pages/ToDoList/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_projects/type/:type?/:time?",
    component: () =>
        import ("../pages/ToDoProject"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_project/:project_id/phases",
    component: () =>
        import ("../pages/ToDoProject/listPhases.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/phases/:phase_id/tasks",
    component: () =>
        import ("../pages/ToDoProject/listTasks.vue"),
    beforeEnter: requireAuth,
});



routes.push({
    path: "/to_do_projects/form/:id?",
    component: () =>
        import ("../pages/ToDoProject/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_categories",
    component: () =>
        import ("../pages/ToDoCategory"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_categories/form/:id?",
    component: () =>
        import ("../pages/ToDoCategory/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_phases",
    component: () =>
        import ("../pages/ToDoPhase"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_phases/form/:to_do_project_id/:id?",
    component: () =>
        import ("../pages/ToDoPhase/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_tasks",
    component: () =>
        import ("../pages/ToDoTask"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_tasks/form/:to_do_phase_id/:id?",
    component: () =>
        import ("../pages/ToDoTask/form.vue"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_dashboard",
    component: () =>
        import ("../pages/ToDoDashboard"),
    beforeEnter: requireAuth,
});
routes.push({
    path: "/to_do_project_manager",
    component: () =>
        import ("../pages/ToDoProjectManager"),
    beforeEnter: requireAuth,
});

routes.push({
    path: "/to_do_project_manager/form/:id?",
    component: () =>
        import ("../pages/ToDoProjectManager/form.vue"),
    beforeEnter: requireAuth,
});


Vue.use(Router);
export default new Router({
    mode: "history",
    routes,
});